@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
  --accent-blue: #6ed0eb;
  --accent-pink: #de72da;
}

html {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.mono-font {
    font-family: 'IBM Plex Mono', monospace;
}

.button-shadow {
    box-shadow: -1px 1px 1px 0 rgb(0 0 0);
}

.button-shadow:active {
    box-shadow: inset -1px 1px 1px 0 rgb(0 0 0);
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@layer components {
    .hover-lift {
        @apply transition hover:scale-105 hover:shadow-xl;
    }

    .legal-page li {
      margin: 1rem 0rem
    }
    .legal-page h1 {
      margin-top: 1rem;
      margin-bottom: .5rem;
      @apply text-xl font-bold;
    }
    .legal-page h2 {
      margin-top: .5rem;
      margin-bottom: .25rem;
      @apply text-lg;
    }
    .legal-page h3 {
      margin-top: .5rem;
      margin-bottom: .25rem;
      @apply text-lg
    }

    .legal-page td {
      border: 1px solid;
      @apply border-neutral-600;
      padding: .5rem;
    }

    .legal-page table {
      margin: 1rem;
    }
}



input[type=range] {
  margin: 10px 0px;
  width: 100%;
  height: 10px;
  accent-color: green;
  background-color: #d6dee1;
  color: #d6dee1;
  background-image: linear-gradient(#fff, #fff);

  /* 30% is the difference between the max and the current value (100 - 70) */
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -2px;
} 
/* input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: #e0f2fe;
    border-radius: 5px;
  } */
